<!-- eslint-disable vue/no-v-html -->
<template>
  <Container itemscope itemtype="https://schema.org/Question" size="medium">
    <div class="bg-white rounded-lg shadow">
      <button
        @click="open = !open"
        class="flex w-full border-0 bg-transparent p-0 items-center cursor-pointer gap-4 p-4 mini:p-6 text-[inherit]"
        aria-label="Show answer"
      >
        <Headline :level="2" :size="5" :no-margin="true" class="flex-1">
          <span itemprop="name">
            {{ question }}
          </span>
        </Headline>
        <SvgIcon :icon="open ? 'chevron-up' : 'chevron-down'" class="w-5 h-5" />
      </button>
      <div
        itemscope
        itemtype="https://schema.org/Answer"
        itemprop="acceptedAnswer"
        class="grid transition-all duration-500 ease-in-out overflow-hidden"
        :class="open ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'"
      >
        <div class="overflow-hidden">
          <div class="px-6 pb-6 mini:px-8 mini:pb-8">
            <!-- eslint-disable-next-line -->
            <RichText itemprop="text" :content="answer" />
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script setup lang="ts">
  const open = ref(false)

  defineProps({
    question: {
      type: String,
      default: "",
    },
    answer: {
      type: String,
      default: "",
    },
  })
</script>
