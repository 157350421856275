<template>
  <Faq :question="question" :answer="answer" />
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      question() {
        return this.getValue("question")
      },
      answer() {
        return this.getValue("answer")
      },
    },
  }
</script>
